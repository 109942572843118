.icon {
  transition: all 300ms linear;
}

.icon:global(.active) {
  transform: rotate(-135deg);
  transition: all 300ms linear;
}

.accordion :global(.accordion__content) {
  max-height: 0;
  transition: all 0.3s linear;
  overflow: hidden;
}

.accordion :global(.accordion__content--expanded) {
  transition: all 0.3s linear;
}

.desktop_navDropdownContent__L6fCl {
  animation-duration: 250ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.desktop_navDropdownContent__L6fCl[data-motion='from-start'] {
  animation-name: desktop_enterFromLeft__FaQkD;
}
.desktop_navDropdownContent__L6fCl[data-motion='from-end'] {
  animation-name: desktop_enterFromRight__2EHuM;
}
.desktop_navDropdownContent__L6fCl[data-motion='to-start'] {
  animation-name: desktop_exitToLeft__Qm9NP;
  display: none;
}
.desktop_navDropdownContent__L6fCl[data-motion='to-end'] {
  animation-name: desktop_exitToRight__SH_Fg;
  display: none;
}

.desktop_navViewport__DC1YG {
  background: linear-gradient(to right, #ffffff 0%, #f8f9fa 50%);
  height: var(--radix-navigation-menu-viewport-height);
  transform-origin: top center;
  transition: width 300ms ease, height, 300ms ease;
  overflow: hidden;
}

.desktop_navViewport__DC1YG[data-state='open'] {
  animation: desktop_enterIntoNav__eaUTG 500ms ease;
}
.desktop_navViewport__DC1YG[data-state='closed'] {
  animation: desktop_exitFromNav__JTuJf 200ms ease;
}

/* keyframes */
@keyframes desktop_enterIntoNav__eaUTG {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes desktop_exitFromNav__JTuJf {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes desktop_enterFromLeft__FaQkD {
  from {
    transform: translateX(-25px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes desktop_enterFromRight__2EHuM {
  from {
    transform: translateX(25px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes desktop_exitToLeft__Qm9NP {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-25px);
    opacity: 0;
  }
}

@keyframes desktop_exitToRight__SH_Fg {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(25px);
    opacity: 0;
  }
}

.__icon__DvM_W {
  transition: all 300ms linear;
}

.__icon__DvM_W.active {
  transform: rotate(-135deg);
  transition: all 300ms linear;
}

.__accordion__juzyv .accordion__content {
  max-height: 0;
  transition: all 0.3s linear;
  overflow: hidden;
}

.__accordion__juzyv .accordion__content--expanded {
  transition: all 0.3s linear;
}

.mobile_hamburger__vITWI {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px 0;
}

.mobile_hamburger__vITWI > span {
  background: var(--gray-90);
  display: block;
  width: 1.5rem;
  height: 2px;
  border-radius: 8px;
  transition: transform 300ms ease;
}

.mobile_hamburger__vITWI > span:nth-child(1) {
  margin-bottom: 5px;
}

.mobile_hamburger__vITWI > span:nth-child(2) {
  margin-bottom: 5px;
}

.mobile_hamburger__vITWI.active > span:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.mobile_hamburger__vITWI.active > span:nth-child(2) {
  visibility: hidden;
}

.mobile_hamburger__vITWI.active > span:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

.mobile_mobileNavDropdown__1CzIQ {
  --nav-header-height: 4.5rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: var(--nav-header-height);
  height: calc(var(--vh, 1vh) * 100 - var(--nav-header-height));
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out, height 500ms ease;
}

.mobile_mobileNavDropdown__1CzIQ.active {
  transform: translateX(0px);
}

.mobile_mobileNavDropdown__1CzIQ .mobile_menuItem__IyF5w,
.mobile_mobileNavDropdown__1CzIQ .accordion__header {
  position: relative;
  color: var(--gray-90);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 34px;
  padding: 16px 24px;
}

.mobile_mobileNavDropdown__1CzIQ .mobile_menuItem__IyF5w::before,
.mobile_mobileNavDropdown__1CzIQ .accordion__header::before {
  content: '';
  background: var(--gray-30);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
}

.mobile_mobileNavDropdown__1CzIQ .accordion__header.active::after {
  background: none;
}

.mobile_mobileNavDropdown__1CzIQ .accordion--lg .accordion__content--expanded {
  max-height: -moz-max-content;
  max-height: max-content;
}

.mobile_mobileNavDropdown__1CzIQ .accordion__icon {
  color: var(--gray-80);
  margin: 0 1rem;
}

.mobile_mobileNavDropdown__1CzIQ .accordion__header.active .mobile_mobileNavTitle___hhxh {
  color: var(--blue-50);
}

@font-face {
font-family: '__articulatCF_502362';
src: url(/_next/static/media/c19ce5cf55e9a5ef-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__articulatCF_502362';
src: url(/_next/static/media/94f62cad496e9af6-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__articulatCF_502362';
src: url(/_next/static/media/f7a0a236a219e4bc-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__articulatCF_Fallback_502362';src: local("Arial");ascent-override: 110.60%;descent-override: 40.54%;line-gap-override: 18.02%;size-adjust: 99.91%
}.__className_502362 {font-family: '__articulatCF_502362', '__articulatCF_Fallback_502362'
}.__variable_502362 {--font-articulat-cf: '__articulatCF_502362', '__articulatCF_Fallback_502362'
}

@font-face {
font-family: '__onest_4265cb';
src: url(/_next/static/media/029eba88201785bc-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__onest_4265cb';
src: url(/_next/static/media/af9f06951a9aefdb-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__onest_Fallback_4265cb';src: local("Arial");ascent-override: 91.30%;descent-override: 28.71%;line-gap-override: 0.00%;size-adjust: 106.24%
}.__className_4265cb {font-family: '__onest_4265cb', '__onest_Fallback_4265cb'
}.__variable_4265cb {--font-onest: '__onest_4265cb', '__onest_Fallback_4265cb'
}

.__logoSlider__I9D_m:nth-child(1) > *:nth-child(-n + 6) {
  display: block;
}

@media (min-width: 640px) {
  .__logoSlider__I9D_m {
    --animation-duration: calc(2s * var(--content-types));
    animation: __marquee__ywa3N linear infinite;
  }

  .__gradientAtMargin__bcALp:hover .__logoSlider__I9D_m {
    animation-play-state: paused;
  }
  .__gradientAtMargin__bcALp::before,
  .__gradientAtMargin__bcALp::after {
    content: '';
    position: absolute;
    width: 64px;
    height: 100%;
    top: 0;
  }
  .__gradientColorAtDark__3RQJp::before {
    background: linear-gradient(90deg, #070425 20%, #07042500 100%);
    z-index: 10;
    left: 0px;
  }
  .__gradientColorAtDark__3RQJp::after {
    background: linear-gradient(270deg, #070425 0%, #07042500 100%);
    right: 0px;
  }

  .__logoSlider__I9D_m:nth-child(1) > *:nth-child(-n + 6) {
    display: hidden;
  }
}

@keyframes __marquee__ywa3N {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.__title__cXGq4 u {
  text-decoration: none;
}

.__title__cXGq4 u > span {
  text-decoration-thickness: 4px !important;
  text-underline-offset: 8px;
  text-decoration: underline;
}

.__title__cXGq4 .__animate__FgW1O {
  animation: __fade-in___EFKj 0.5s;
}

@keyframes __fade-in___EFKj {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.__svgImageCircle__FQ09p {
  stroke-dasharray: 140px; /* this value is the pixel circumference of the circle  r=22 ~ 140 */
  stroke-dashoffset: 140px;
  animation: __dash__ms1Vy 4.5s linear 200ms both; /* total should ~5s i.e auto scroll of the carousel */
}

@keyframes __dash__ms1Vy {
  from {
    stroke-dashoffset: 140px;
  }
  to {
    stroke-dashoffset: 0px;
  }
}

.__hoverBorder__7erbX {
  position: relative;
}

.__hoverBorder__7erbX::after,
.__activeBorder__5qoxg::after {
  content: '';
  position: absolute;
  height: 4px;
  bottom: 0;
  background: var(--purple-50);
}

.__hoverBorder__7erbX::after {
  width: 0%;
  left: 50%;
  transition: all 300ms ease-in-out;
}

.__activeBorder__5qoxg::after {
  width: 100%;
  left: 0%;
}

.__hoverBorder__7erbX:hover::after {
  left: 0%;
  width: 100%;
}

.__animate__qrxLY {
  animation: __animate__qrxLY 500ms ease-in;
}

@keyframes __animate__qrxLY {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.__scrollbar__wpYuC::-webkit-scrollbar {
  height: 8px;
}

.__scrollbar__wpYuC::-webkit-scrollbar-track {
  background: rgba(217, 217, 217, 0.16);
  border-radius: 3.75rem;
}

.__scrollbar__wpYuC::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 3.75rem;
}

.__text__xjj8p::before {
  content: url('/assets/homePage/images/footer-star-2.png');
  position: absolute;
  right: 0;
  top: -1rem;
}

.__text__xjj8p::after {
  content: url('/assets/homePage/images/footer-star-1.png');
  position: absolute;
}


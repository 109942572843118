.scrollbar::-webkit-scrollbar {
  height: 8px;
}

.scrollbar::-webkit-scrollbar-track {
  background: rgba(217, 217, 217, 0.16);
  border-radius: 3.75rem;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 3.75rem;
}

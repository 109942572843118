.svgImageCircle {
  stroke-dasharray: 140px; /* this value is the pixel circumference of the circle  r=22 ~ 140 */
  stroke-dashoffset: 140px;
  animation: dash 4.5s linear 200ms both; /* total should ~5s i.e auto scroll of the carousel */
}

@keyframes dash {
  from {
    stroke-dashoffset: 140px;
  }
  to {
    stroke-dashoffset: 0px;
  }
}

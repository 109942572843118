.title u {
  text-decoration: none;
}

.title u > span {
  text-decoration-thickness: 4px !important;
  text-underline-offset: 8px;
  text-decoration: underline;
}

.title .animate {
  animation: fade-in 0.5s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

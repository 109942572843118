.hoverBorder {
  position: relative;
}

.hoverBorder::after,
.activeBorder::after {
  content: '';
  position: absolute;
  height: 4px;
  bottom: 0;
  background: var(--purple-50);
}

.hoverBorder::after {
  width: 0%;
  left: 50%;
  transition: all 300ms ease-in-out;
}

.activeBorder::after {
  width: 100%;
  left: 0%;
}

.hoverBorder:hover::after {
  left: 0%;
  width: 100%;
}

.animate {
  animation: animate 500ms ease-in;
}

@keyframes animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.text::before {
  content: url('/assets/homePage/images/footer-star-2.png');
  position: absolute;
  right: 0;
  top: -1rem;
}

.text::after {
  content: url('/assets/homePage/images/footer-star-1.png');
  position: absolute;
}

.hamburger {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px 0;
}

.hamburger > span {
  background: var(--gray-90);
  display: block;
  width: 1.5rem;
  height: 2px;
  border-radius: 8px;
  transition: transform 300ms ease;
}

.hamburger > span:nth-child(1) {
  margin-bottom: 5px;
}

.hamburger > span:nth-child(2) {
  margin-bottom: 5px;
}

.hamburger:global(.active) > span:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.hamburger:global(.active) > span:nth-child(2) {
  visibility: hidden;
}

.hamburger:global(.active) > span:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

.mobileNavDropdown {
  --nav-header-height: 4.5rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: var(--nav-header-height);
  height: calc(var(--vh, 1vh) * 100 - var(--nav-header-height));
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out, height 500ms ease;
}

.mobileNavDropdown:global(.active) {
  transform: translateX(0px);
}

.mobileNavDropdown .menuItem,
.mobileNavDropdown :global(.accordion__header) {
  position: relative;
  color: var(--gray-90);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 34px;
  padding: 16px 24px;
}

.mobileNavDropdown .menuItem::before,
.mobileNavDropdown :global(.accordion__header::before) {
  content: '';
  background: var(--gray-30);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
}

.mobileNavDropdown :global(.accordion__header.active::after) {
  background: none;
}

.mobileNavDropdown :global(.accordion--lg .accordion__content--expanded) {
  max-height: max-content;
}

.mobileNavDropdown :global(.accordion__icon) {
  color: var(--gray-80);
  margin: 0 1rem;
}

.mobileNavDropdown :global(.accordion__header.active) .mobileNavTitle {
  color: var(--blue-50);
}
